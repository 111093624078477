export default {
  render: () => null,
  data() {
    return {
      internalValue: '',
      timer: null
    }
  },
  created() {
    document.addEventListener('keypress', this.keypress)
    document.addEventListener('paste', this.paste)
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.keypress)
    document.removeEventListener('paste', this.paste)
  },
  methods: {
    reset() {
      this.internalValue = ''
    },
    resetTimer() {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(this.reset,500)
    },
    keypress(e) {
      if(e.key) {
        if(e.keyCode == 13) {
          this.internalValue && this.$emit('input',this.internalValue)  
          this.reset()     
        } else if(
          ( e.keyCode > 47 && e.keyCode < 58) // numeric (0-9)
          || ( e.keyCode > 64 && e.keyCode < 91) // upper alpha (A-Z)
          || ( e.keyCode > 96 && e.keyCode < 123 ) // lower alpha (a-z)
        ) {
            this.internalValue += e.key
        }
      }
    },
    paste(e) {
      const data = e.clipboardData.getData('text')
      data && this.$emit('input', data)
    }
  }
}