<template>
  <b-modal 
    ref="modal" 
    ok-only
    :title="$t('stores.consolidate.title',{barcode: item.barcode})"
    body-class="text-center"
  >
    <BarcodeListener @input="consolidate($event)" />
    <div>
      <template v-if="loading">
        {{$t('stores.consolidate.loading')}} <br>
        <i style="font-size: 6rem;" class='bx bx-loader-circle bx-spin text-gray-700' ></i>
      </template>
      <template v-else-if="working">
        {{$t('stores.consolidate.consolidating')}} <br>
        <i style="font-size: 6rem;" class='bx bx-loader-circle bx-spin text-gray-700' ></i>
      </template>
      <template v-else>
        {{$t('stores.consolidate.awaiting-input')}} <br>
        <i style="font-size: 6rem;" class='bx bx-dots-horizontal-rounded bx-flashing text-success' ></i><br>
      </template>

      <b-container class="text-left">
        <b-row>
          <b-col cols="12">
            <h5>{{$t('stores.consolidate.consolidated',{count: consolidated.length, total: consolidatable.length})}}</h5>
          </b-col>
          <b-col v-for="p in consolidated" :key="p.id" cols="12" lg="6">
            <package-info :item="p" />
          </b-col>
        </b-row>
      </b-container>
      
    </div>

    <template #modal-footer>
      <b-button :disabled="working" @click="close()">{{$t('common.close')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import BarcodeListener from '@/services/BarcodeListener'
import api from '@/api'
import PackageInfo from '../PackageInfo.vue'
import Package from '@/resources/Package'

export default {
  name: 'QuickCheckinModal',
  extends: Modal,
  components: {
    BarcodeListener,
    PackageInfo
  },
  props: {
    item: {
      type: Package,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      working: false,
      consolidatable: [],
      consolidated: [],
    }
  },
  async created() {
    // const {data: {data: items}} = await api.get(`packages/${this.item.id}/consolidate`)
    // this.consolidatable = items
    // this.consolidated = items.filter(p => p.consolidation_id).map(p => p.id)
    this.consolidated = [...this.item.$meta.consolidations]
    this.loading = false
  },
  methods:{
    async consolidate(barcode){
      if(this.working || !barcode) return
      this.working = true

      try {
        console.log(barcode)
        const {data: {data: item}} = await api.put(`packages/${this.item.id}/consolidate/${barcode}`)
        this.consolidated.unshift(item)
      } catch (error) {
        console.log(error)
      } finally {
        this.working = false
      }
    }
  }
}
</script>