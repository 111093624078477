<template>
  <MainLayout fluid header-class="bg-info">
    <h1>
      <i class='bx bxs-truck bx-flip-horizontal' />
      {{$t('driver.title')}}:
      <span v-text="`${user.first_name} ${user.last_name}`" />
    </h1>

    <b-input-group size="lg" class="mb-4">
      <template #prepend>
        <b-button variant="primary" @click="scan">
          <i class='bx bx-scan' :class="{'bx-spin': loading}" ></i>
        </b-button>
      </template>
      <b-input 
        ref="barcode"
        autofocus 
        autocomplete="off"
        :placeholder="$t('driver.input-placeholder')"
        v-model="internalBarcode"
        @keypress="preventIllegal"
        @keyup.enter="getPackage"
        @paste="pasted"
      ></b-input>
      <b-input-group-append>
        <b-button @click="quickPickup" variant="primary"><i class='bx bxs-bolt' ></i></b-button>
      </b-input-group-append>
    </b-input-group>

    <h3 v-if="packages.length" class="mb-4">
      <i class='bx bx-package'></i> 
      {{$t('packages.title')}}
      <span class="text-muted">({{packages.length}})</span>
    </h3>

    <b-container fluid class="p-0">
      <b-row v-for="(p,location) in packagesByLocation" :key="location">
        <b-col cols="12">
          <h5 class="mb-4">
            <i class='bx bx-map'></i> 
            {{location}} 
            <span class="text-muted">({{p.length}})</span>
          </h5>
        </b-col>
        <b-col v-for="item in p" :key="item.id" cols="12" sm="6" lg="4" xl="3">
          <PackageInfo v-bind="{item}" @click="openPackage(item)" detail="date" />
        </b-col>
      </b-row>
    </b-container>

  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main";
import api from '@/api';
import Package from '@/resources/Package';
// import { FEATURE_FETCHING } from '@/resources/Merchant';
import DriverModal from '@/components/Modals/Driver.vue'
import BarcodeScanner from '@/components/Modals/BarcodeScanner.vue'
import { mapGetters } from 'vuex';
import QuickPickup from '@/components/Modals/QuickPickup.vue';
import PackageInfo from "@/components/PackageInfo.vue";
import { vibrate } from '@/utils';
import FetchPackage from "@/components/Modals/FetchPackage.vue";

export default {
  name: 'Driver',
  components: {
    MainLayout,
    PackageInfo,
  },
  data() {
    return {
      packages: [],
      loading: false,
      barcode: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      features: 'auth/features',
    }),
    internalBarcode: {
      get() {
        return this.barcode
      },
      set(value) {
        this.barcode = value.replace(/\s/g, '').toUpperCase()
      }
    },
    packagesByLocation() {
      return this.packages.reduce((c,p) => {
        let location = p.dropoff_location_name;
        if(!(location in c)) c[location] = []
        c[location].push(p)
        return c
      },{})
    },
  },
  created() {
    this.getPackages()
  },
  methods: {
    async getPackages() {
      const {data: {data: packages}} = await api.get('driver/packages')

      this.packages = packages
    },
    async quickPickup() {
      await this.$modal(QuickPickup)

      this.getPackages()
    },
    async scan() {
      const results = await this.$modal(BarcodeScanner)
      if(results) {
        this.internalBarcode = results
        this.pasted()
      }
    },
    pasted() {
      setTimeout(() => {
        this.getPackage()
      },100)
    },
    async getPackage() {
      if(this.loading) return
      this.loading = true

      try {
        let {data: {data,meta}} = await api.get(`driver/get`,{
          params: {
            barcode: this.barcode
          }, 
          suppress: [404]
        })
  
        const response = await this.$modal(DriverModal,{
          item: new Package(data,meta)
        })

        if(response?.barcode) {
          this.$nextTick(() => {
            this.openPackage(response)
          })
        }
      } catch (error) {
        vibrate()
        const fetch = await this.$bvModal.msgBoxConfirm(this.$t('driver.errors.not-found-fetch'),{
          autoFocusButton: 'cancel',
          okTitle: this.$t('driver.actions.fetch-package'),
        })

        if(fetch) {
          await this.fetchShipment(this.barcode)
        }
      } finally {
        this.loading = false
        this.barcode = null
        this.getPackages()
        this.$refs.barcode.focus()
      }
    },
    openPackage(item) {
      this.barcode = item.barcode 
      this.pasted()
    },
    preventIllegal(e) {
      /[A-Z0-9]/.test(e.key.toUpperCase()) || e.preventDefault()
    },
    async fetchShipment(barcode) {
      const fetchedBarcode = await this.$modal(FetchPackage, {barcode})
      
      console.log({fetchedBarcode})
      if(fetchedBarcode) {
        setTimeout(() => {
          this.barcode = fetchedBarcode
          this.pasted()
        },100)
      }
    }
  }
}
</script>