<template>
  <b-modal 
    ref="modal"
    no-body
  >
    <template #modal-title>
      Barcode scanner
    </template>

    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90%;"
    >
      <StreamBarcodeReader 
        @decode="onDecode"
        @error="onError"
        @loaded="loading = false"
      />
    </b-overlay>


    <template #modal-footer>
      <b-button @click="close(null)">{{$t('common.cancel')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: 'BarcodeScanner',
  extends: Modal,
  components: {StreamBarcodeReader},
  props: {
  },
  mounted() {
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    onDecode(results) {
      this.close(results)
    },
    onError(e) {
      console.error(e)
    }
  }
}
</script>