<template>
  <b-modal 
    ref="modal" 
    ok-only
    :title="$t('driver.quick-pickup.title')"
    body-class="text-center"
  >
    <BarcodeListener @input="pickup($event)" />
    <div>
      <template v-if="working">
        {{$t('driver.quick-pickup.checking-in')}} <br>
        <i style="font-size: 6rem;" class='bx bx-loader-circle bx-spin text-gray-700' ></i>
      </template>
      <template v-else>
        {{$t('driver.quick-pickup.awaiting-input')}} <br>
        <i style="font-size: 6rem;" class='bx bx-dots-horizontal-rounded bx-flashing text-success' ></i><br>
      </template>

      <b-container class="text-left" v-if="pickedUp.length">
        <b-row>
          <b-col cols="12">
            <h5>{{$t('driver.quick-pickup.picked-up',{count: pickedUp.length})}}</h5>
          </b-col>
          <b-col v-for="item in pickedUp" :key="item.id" cols="12" lg="6">
            <package-info v-bind="{item}" />
          </b-col>
        </b-row>
      </b-container>
      
    </div>

    <template #modal-footer>
      <b-button :disabled="working" @click="close()">{{$t('common.close')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import BarcodeListener from '@/services/BarcodeListener'
import api from '@/api'
import PackageInfo from '../PackageInfo.vue'
import { vibrate } from '@/utils'
import Package from '@/resources/Package'

export default {
  name: 'QuickPickupModal',
  extends: Modal,
  components: {
    BarcodeListener,
    PackageInfo
  },
  data() {
    return {
      working: false,
      pickedUp: [],
    }
  },
  methods:{
    async pickup(barcode){
      if(false || this.working || !barcode) return
      this.working = true

      try {
        let {data: {data, meta}} = await api.put(`driver/quick-pickup/${barcode}`)
        const item = new Package(data, meta)
        this.pickedUp.unshift(item)
        item.$meta.consolidatable && vibrate('consolidatable')
      } catch (error) {
        vibrate()
        console.log(error)
      } finally {
        this.working = false
      }
    }
  }
}
</script>